document.addEventListener("DOMContentLoaded", (e) => {
  const scrollContainer = document.getElementById('scrollContainer');
  const scrollContent = document.getElementById('scrollContent');

  let scrollPaused = false;
  let manualScroll = false;
  let scrollDirection = 1;
  const scrollSpeed = 1;

  function updateScrollDirection() {
    const scrollLeft = scrollContainer.scrollLeft;
    const containerWidth = scrollContainer.clientWidth;
    const contentWidth = scrollContent.scrollWidth;

    if (scrollLeft + containerWidth >= contentWidth && scrollDirection === 1) {
        scrollDirection = -1;
    } else if (scrollLeft <= 1 && scrollDirection === -1) {
        scrollDirection = 1;
    }
  }

  function scroll() {
      if (!scrollPaused && !manualScroll) {
          updateScrollDirection();
          scrollContainer.scrollLeft += scrollDirection * scrollSpeed;
      }

      requestAnimationFrame(scroll);
  }

  scrollContainer.addEventListener('mouseenter', () => {
    scrollPaused = true;
  });

  scrollContainer.addEventListener('mouseleave', () => {
      scrollPaused = false;
  });

  scrollContainer.addEventListener('touchstart', () => {
    stopAutoScroll();
    scrollPaused = true;
  });

  scrollContainer.addEventListener('touchmove', () => {
    stopAutoScroll();
    scrollPaused = true;
  });

  scrollContainer.addEventListener('touchend', () => {
    scrollPaused = false;
  });

  function stopAutoScroll() {
    manualScroll = true;
    clearTimeout(manualScrollTimeout);
    const manualScrollTimeout = setTimeout(() => {
        manualScroll = false;
    }, 5000);
  };


  requestAnimationFrame(scroll);
});
